import React,{useState,useRef} from 'react'
import { useReactToPrint } from "react-to-print";
import { MDBIcon, MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody } from 'mdb-react-ui-kit'

const PaymentReceipt = ({data,program,modal,setModal}) => {
    const [fullscreenXlModal, setFullscreenXlModal] = useState(true);
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      //   pageStyle: "@page { margin-top: 0px;  } ",
      content: () => componentRef.current,
    });
  return (
    <div>
        {data && <MDBModal tabIndex='-1' show={modal} setShow={setModal}>
    <MDBModalDialog size='fullscreen-xxl-down'>
      <MDBModalContent>
        <MDBModalBody>
          <div style={{ width: '700px', margin: 'auto' }} className='border border-black' ref={componentRef}>
            <div className='m-5 border border-black'>
              <div className='m-4'>
                <img style={{ width: '11rem', margin: 'auto' }} src="https://ucarecdn.com/310f986c-7286-41bf-9bfd-79673ec9eff1/AICEE2023LOGO4.png" alt="" />
              </div>
              <div className='text-center text-black'>
                <h5 className='font-bold' style={{fontWeight:'bold'}}>All India Combined Entrance Examination</h5>
              </div>
              <div className='text-center text-black font-bold mt-[-10px]'>
                <h5 className='font-bold' style={{ marginTop: '1.3rem', textDecoration: 'underLine',fontSize:'17px' }}>Electronic Receipt</h5>
              </div>
              <div className='text-center text-black font-bold '>
                <h6 className='' style={{ marginTop: '1rem' }}>Application No : <span className='font-bold'>{data[program].applicationNo}</span></h6>
              </div>
              <div className='text-start pl-3 text-black mt-1' style={{ fontSize: '0.9rem' ,lineHeight:'15px',textAlign:'justify'}}>
                <p className='' style={{ marginTop: '1.8rem' }}>To  <span className='font-bold'>{data.name}</span></p>
                <p className='font-bold' style={{ marginTop: '-0.8rem', fontSize: '0.8rem' }}>Online Application successfully Submitted. </p>
                <p className='text-black ' style={{ marginTop: '-0.8rem', fontSize: '0.8rem',color:'black' }}>Amount Received towards <span className='font-bold'> {program == 'mba' ? "PG Application Form 2025" : "UG Application Form 2025"}</span> </p>
                <p className='' style={{ marginTop: '-0.8rem', fontSize: '0.8rem' }}>Date of Receipts : <span className='font-bold'>{data[program].payDate}</span> </p>
                <p className='' style={{ marginTop: '-0.8rem', fontSize: '0.8rem' }}>Payment Type : <span className='font-bold'>Online</span> </p>
                <p className='' style={{ marginTop: '-0.8rem', fontSize: '0.8rem' }}>Application Fee : <span className='font-bold'>{data[program].amount}</span> </p>
                <p className='' style={{ marginTop: '-0.8rem', fontSize: '0.8rem', marginBottom: '1rem' }}>Transaction Id : <span className='font-bold'>{data[program].transId}</span> </p>
              <div className='w-[100%] m-auto text-center' style={{width:'100%', margin:'auto',lineHeight:'4px',fontSize:'13px'}}>
              <p className='font-bold'>
              &#9745;	 I, accept that fees paid is non refundable.
              </p>
              <p>
                This is system generated receipt and does not require signature.
              </p>
              </div>
              </div>
            </div>
          </div>
          <center className='pb-5 mt-2'>
            <MDBBtn onClick={() => { setModal(!modal) }} color='success'>Close</MDBBtn>
            <MDBBtn className='ml-3' color='primary' onClick={handlePrint}>Print</MDBBtn>
          </center>
        </MDBModalBody>
        {/* <MDBModalFooter> */}
        {/* </MDBModalFooter> */}
      </MDBModalContent>
    </MDBModalDialog>
  </MDBModal>}
    </div>
  )
}

export default PaymentReceipt